@use 'sass:map';
@use 'sass:math';

// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// MIXINS/GENERAL
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

// font-size
@mixin font-size($font-size, $line-height: auto) {
    @warn "The font-size mixin is deprecated. Please use font-size and line-height instead. Das font-size mixin ist veraltet. Bitte verwende stattdessen font-size und line-height.";
}

// margin-auto
@mixin margin-auto {
    margin-right: auto;
    margin-left: auto;
}

// basic styles for lists used as navs
@mixin navigation() {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    a {
        text-decoration: none;
    }
}

// vertical align
@mixin vertical-align($position: 50%, $correct: -50%) {
    position: relative;
    top: $position;
    transform: translateY($correct);
}

@mixin padding-default($column__gutter--half: $grid__gutter--half) {
    padding-right: $column__gutter--half;
    padding-left: $column__gutter--half;
}

// make-row
@mixin make-row($column__gutter--half: $grid__gutter--half) {
    @extend %clearfix;
    margin-right: $column__gutter--half * -1;

    margin-left: $column__gutter--half * -1;
}

// column-floating
@mixin column-floating($column__gutter--half: $grid__gutter--half) {
    float: left;
    padding-right: $column__gutter--half;
    padding-left: $column__gutter--half;
}

// make-column mixin
@mixin make-col($columns) {
    @include column-floating($grid__gutter--half);

    width: math.percentage($columns / $grid__columns);
}

// make-width mixin
// [1] fallback for browser that can't calculate
@mixin make-width($width, $align: auto) {
    $width-percentage: $width / $grid__columns * $wrapper-max;

    max-width: $width-percentage; // [1]
    max-width: calc(#{$width-percentage} - #{$grid__gutter});

    @if $align == auto or center {
        margin-right: auto;
        margin-left: auto;
    }

    @if $align == left {
        margin-right: auto;
        margin-left: 0;
    }

    @if $align == right {
        margin-right: 0;
        margin-left: auto;
    }
}

// make-offset
@mixin make-offset($width, $direction: left) {
    @if $direction == left or none {
        margin-left: math.percentage($width / $grid__columns);
    }
}

// screen reader only
@mixin invisible {
    position: absolute;
    top: -1000px;
    left: -1000px;
    display: inline;
    width: 0;
    height: 0;
    overflow: hidden;
}

// Functions
// function map-deep-get()
// function to get a value from a nested map, see _type.scss for usage
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map.get($map, $key);
    }

    @return $map;
}

// function headings()
// function to generate headlines h1 – h6
// usage:
// #{headings()} { … } will result in
// h1, h2, … h6 { … }
// ---
// #{headings(2,4) { … } will result in
// h2,h3,h4 { … }
// ---
@function headings($first: 1, $last: 6) {
    @if $first == $last {
        @return 'h#{$first}';
    } @else {
        @return 'h#{$first},' + headings($first + 1, $last);
    }
}
