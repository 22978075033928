// TRUMPS/HELPER
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss
// $color-text--inverted
// $base-border
// $base-spacing-unit

// USED FROM _grid.scss
// $grid__columns

// USED FROM _responsive.scss
// $breakpoints

// --------------------------------------------------

.block--center {
    @include margin-auto;
}

// spacing classes for articles and elements beginning from .m-t-0 up to .m-t-5
@for $i from 0 through 10 {
    .m-t-#{$i},
    .mod_article.m-t-#{$i} {
        margin-top: $i * $base-spacing-unit !important;
    }

    .m-b-#{$i},
    .mod_article.m-b-#{$i} {
        margin-bottom: $i * $base-spacing-unit !important;
    }

    .p-t-#{$i},
    .mod_article.p-t-#{$i} {
        padding-top: $i * $base-spacing-unit;
    }

    .p-b-#{$i},
    .mod_article.p-b-#{$i} {
        padding-bottom: $i * $base-spacing-unit;
    }
}
