// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// COMPONENTS/ACCORDION
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

.ce_rsce_jahreseinkommen {
    p {
        position: relative;
        display: inline-block;
        margin: 1rem 0 3rem 3rem;
        padding: 0.5rem 2rem;
        color: $color-text--inverted;
        background: url('../../images/jahreseinkommen_ribbon.svg') left top no-repeat;
        background-size: auto 100%;

        @include media-query(screen-md) {
            width: 330px;
        }

        &::before {
            position: absolute;
            top: -1rem;
            left: -3rem;
            display: block;
            width: 4rem;
            height: 5rem;
            background: url('../../images/jahreseinkommen_pig.svg') left top no-repeat;
            background-size: contain;
            content: '';
        }
    }
}

.user-figures {
    .figure {
        + .figure {
            margin-top: $base-spacing-unit;
        }

        &__number {
            display: inline-block;
            width: 4.33em;
            margin-right: 0.5rem;
            padding: 0.25rem;
            color: $color-brand;
            font-weight: 700;
            font-size: 1.75rem;
            line-height: 1;
            text-align: center;
            vertical-align: middle;
            border: 0.25rem solid $color-brand;
        }

        &__label {
            color: $color-brand;
            font-size: 0.9rem;
        }
    }
}

.ce_rsce_interessen {
    @include media-query(screen-md) {
        overflow: visible;
    }
}

.user-interests {
    height: 10rem;

    @include media-query(screen-sm-max) {
        text-align: left;
    }

    @include media-query(screen-md) {
        font-size: 1.25rem;
    }

    .interest {
        position: relative;
        display: inline-block;
        margin-left: -0.5rem;

        &--0 {
            margin-left: 0;
        }

        &__pin {
            svg {
                width: 3em;
                height: 4em;
            }
        }

        $pin-colors: (
            0: (
                fill: #4e8fcc,
            ),
            1: (
                fill: #8faedc,
            ),
            2: (
                fill: #adc9eb,
            ),
            3: (
                fill: #b2d4f1,
            ),
            4: (
                fill: #c6e1f7,
            ),
            5: (
                fill: #ceeafc,
            ),
        );

        @for $i from 0 through 5 {
            &--#{$i} {
                .interest__pin {
                    path {
                        fill: map-deep-get($pin-colors, $i, 'fill');
                    }
                }
            }
        }

        &__icon {
            position: absolute;
            top: 0.9em;
            left: 0.73em;

            svg {
                width: 1.5em;
                height: 1.5em;
            }

            path {
                fill: #fff;
            }
        }

        &__label {
            position: absolute;
            left: 1.75rem;
            display: block;
            width: 5rem;
            margin-top: 0.125em;
            margin-left: 0.5em;
            color: $color-brand;
            font-weight: 400;
            font-size: 0.7rem;
            white-space: nowrap;
            text-align: left;
            text-transform: uppercase;
            transform: rotate(22deg);
        }
    }
}

@keyframes bake-pie {
    from {
        transform: rotate(0deg) translate3d(0, 0, 0);
    }
}

.pie-chart {
    @include media-query(screen-sm-max) {
        margin-bottom: 4rem;

        .col-md-4:last-child & {
            margin-bottom: 0;
        }
    }

    &__pie,
    &__legend {
        display: inline-block;
        vertical-align: top;
    }

    &__pie {
        position: relative;
        width: 200px;
        height: 200px;
        margin: 10px auto 35px;

        &::before {
            position: absolute;
            top: 50px;
            left: 50px;
            z-index: 1;
            display: block;
            width: 100px;
            height: 100px;
            background: #eee;
            border-radius: 50%;
            content: '';
        }

        &::after {
            display: block;
            width: 120px;
            height: 2px;
            margin: 220px auto;
            background: rgb(0 0 0 / 10%);
            border-radius: 50%;
            box-shadow: 0 0 3px 4px rgb(0 0 0 / 10%);
            content: '';
        }
    }

    &__legend {
        display: block;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 0.5rem !important;
        padding: 0.75rem 0.75rem 0.05rem;
        font-size: 13px;
        text-align: left;
        list-style: none;
        background: #fff;
        box-shadow:
            1px 1px 0 #ddd,
            2px 2px 0 #bbb;

        li {
            height: 1.15rem;
            margin-bottom: 0.33rem;
            padding-left: 0.5rem;
            overflow: hidden;
            border-left: 1.15rem solid black;
        }

        em {
            font-style: normal;
            @include media-query(screen-sm-max) {
                display: inline-block;
                max-width: 70%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        span {
            float: right;
            clear: both;
        }
    }
}

.slice {
    position: absolute;
    width: 200px;
    height: 200px;
    animation: bake-pie 1s;
    clip: rect(0, 200px, 200px, 100px);

    span {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 200px;
        height: 200px;
        background-color: black;
        border-radius: 50%;
        clip: rect(0, 200px, 200px, 100px);
    }
}
