@use 'sass:map';
@use 'sass:list';

// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// BASE/TYPE
// --------------------------------------------------

// VARIABLES
$headings__font-family: $base-font-family;
$headings__color: #4d4d4d;
$headlines: (
    h1: (
        sizes: (
            xs: 2rem,
            sm: 2rem,
            md: 2.1rem,
            lg: 2.1rem,
            xl: 2.1rem,
        ),
        line-height: 1.55,
        margin-top: 2rem,
        margin-bottom: 1rem,
    ),
    h2: (
        sizes: (
            xs: 1.625rem,
            sm: 1.625rem,
            md: 1.75rem,
            lg: 1.75rem,
            xl: 1.75rem,
        ),
        line-height: 1.5,
        margin-top: 2rem,
        margin-bottom: 1.5rem,
    ),
    h3: (
        sizes: (
            xs: 1.375rem,
            sm: 1.375rem,
            md: 1.5rem,
            lg: 1.5rem,
            xl: 1.5rem,
        ),
        line-height: 1.25,
        margin-top: 2rem,
        margin-bottom: 0.5rem,
    ),
    h4: (
        sizes: (
            xs: 1.25rem,
            sm: 1.25rem,
            md: 1.4rem,
            lg: 1.4rem,
            xl: 1.4rem,
        ),
        line-height: 1,
        margin-top: 1.5rem,
        margin-bottom: 1.25rem,
    ),
    h5: (
        sizes: (
            xs: 1rem,
            sm: 1rem,
            md: 1rem,
            lg: 1rem,
            xl: 1rem,
        ),
        line-height: 1,
        margin-top: 1.5rem,
        margin-bottom: 0.5rem,
    ),
    h6: (
        sizes: (
            xs: 0.75rem,
            sm: 0.75rem,
            md: 0.75rem,
            lg: 0.75rem,
            xl: 0.75rem,
        ),
        line-height: 1,
        margin-top: 1.5rem,
        margin-bottom: 0.5rem,
    ),
) !default;
$heading-1__size: map-deep-get($headlines, 'h1', 'sizes', 'xs');
$heading-1__line-height: map-deep-get($headlines, 'h1', 'line-height') !default;
$heading-1__margin-top: map-deep-get($headlines, 'h1', 'margin-top') !default;
$heading-1__margin-bottom: map-deep-get($headlines, 'h1', 'margin-bottom') !default;
$heading-2__size: map-deep-get($headlines, 'h2', 'sizes', 'xs');
$heading-2__line-height: map-deep-get($headlines, 'h2', 'line-height') !default;
$heading-2__margin-top: map-deep-get($headlines, 'h2', 'margin-top') !default;
$heading-2__margin-bottom: map-deep-get($headlines, 'h2', 'margin-bottom') !default;
$heading-3__size: map-deep-get($headlines, 'h3', 'sizes', 'xs');
$heading-3__line-height: map-deep-get($headlines, 'h3', 'line-height') !default;
$heading-3__margin-top: map-deep-get($headlines, 'h3', 'margin-top') !default;
$heading-3__margin-bottom: map-deep-get($headlines, 'h3', 'margin-bottom') !default;
$heading-4__size: map-deep-get($headlines, 'h4', 'sizes', 'xs');
$heading-4__line-height: map-deep-get($headlines, 'h4', 'line-height') !default;
$heading-4__margin-top: map-deep-get($headlines, 'h4', 'margin-top') !default;
$heading-4__margin-bottom: map-deep-get($headlines, 'h4', 'margin-bottom') !default;
$heading-5__size: map-deep-get($headlines, 'h5', 'sizes', 'xs');
$heading-5__line-height: map-deep-get($headlines, 'h5', 'line-height') !default;
$heading-5__margin-top: map-deep-get($headlines, 'h5', 'margin-top') !default;
$heading-5__margin-bottom: map-deep-get($headlines, 'h5', 'margin-bottom') !default;
$heading-6__size: map-deep-get($headlines, 'h6', 'sizes', 'xs');
$heading-6__line-height: map-deep-get($headlines, 'h6', 'line-height') !default;
$heading-6__margin-top: map-deep-get($headlines, 'h6', 'margin-top') !default;
$heading-6__margin-bottom: map-deep-get($headlines, 'h6', 'margin-bottom') !default;

// USED FROM _variables.scss

// --------------------------------------------------

// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// BASE/TYPE
// --------------------------------------------------

// VARIABLES
$headings__font-family: 'Helvetica' !default;
$headings__font-weight: 300 !default;
$headlines: (
    h1: (
        sizes: (
            xs: 2rem,
            sm: 2rem,
            md: 2.25rem,
            lg: 2.25rem,
            xl: 2.25rem,
        ),
        line-height: 1.55,
        margin-top: 2rem,
        margin-bottom: 0.5rem,
    ),
    h2: (
        sizes: (
            xs: 1.625rem,
            sm: 1.625rem,
            md: 1.75rem,
            lg: 1.75rem,
            xl: 1.75rem,
        ),
        line-height: 1.5,
        margin-top: 2rem,
        margin-bottom: 0.5rem,
    ),
    h3: (
        sizes: (
            xs: 1.375rem,
            sm: 1.375rem,
            md: 1.5rem,
            lg: 1.5rem,
            xl: 1.5rem,
        ),
        line-height: 1.25,
        margin-top: 2rem,
        margin-bottom: 0.5rem,
    ),
    h4: (
        sizes: (
            xs: 1.125rem,
            sm: 1.125rem,
            md: 1.25rem,
            lg: 1.25rem,
            xl: 1.25rem,
        ),
        line-height: 1,
        margin-top: 1.5rem,
        margin-bottom: 0.5rem,
    ),
    h5: (
        sizes: (
            xs: 1rem,
            sm: 1rem,
            md: 1rem,
            lg: 1rem,
            xl: 1rem,
        ),
        line-height: 1,
        margin-top: 1.5rem,
        margin-bottom: 0.5rem,
    ),
    h6: (
        sizes: (
            xs: 0.875rem,
            sm: 0.875rem,
            md: 0.875rem,
            lg: 0.875rem,
            xl: 0.875rem,
        ),
        line-height: 1,
        margin-top: 1.5rem,
        margin-bottom: 0.5rem,
    ),
) !default;
$heading-1__size: map-deep-get($headlines, 'h1', 'sizes', 'xs');
$heading-1__line-height: map-deep-get($headlines, 'h1', 'line-height') !default;
$heading-1__margin-top: map-deep-get($headlines, 'h1', 'margin-top') !default;
$heading-1__margin-bottom: map-deep-get($headlines, 'h1', 'margin-bottom') !default;
$heading-2__size: map-deep-get($headlines, 'h2', 'sizes', 'xs');
$heading-2__line-height: map-deep-get($headlines, 'h2', 'line-height') !default;
$heading-2__margin-top: map-deep-get($headlines, 'h2', 'margin-top') !default;
$heading-2__margin-bottom: map-deep-get($headlines, 'h2', 'margin-bottom') !default;
$heading-3__size: map-deep-get($headlines, 'h3', 'sizes', 'xs');
$heading-3__line-height: map-deep-get($headlines, 'h3', 'line-height') !default;
$heading-3__margin-top: map-deep-get($headlines, 'h3', 'margin-top') !default;
$heading-3__margin-bottom: map-deep-get($headlines, 'h3', 'margin-bottom') !default;
$heading-4__size: map-deep-get($headlines, 'h4', 'sizes', 'xs');
$heading-4__line-height: map-deep-get($headlines, 'h4', 'line-height') !default;
$heading-4__margin-top: map-deep-get($headlines, 'h4', 'margin-top') !default;
$heading-4__margin-bottom: map-deep-get($headlines, 'h4', 'margin-bottom') !default;
$heading-5__size: map-deep-get($headlines, 'h5', 'sizes', 'xs');
$heading-5__line-height: map-deep-get($headlines, 'h5', 'line-height') !default;
$heading-5__margin-top: map-deep-get($headlines, 'h5', 'margin-top') !default;
$heading-5__margin-bottom: map-deep-get($headlines, 'h5', 'margin-bottom') !default;
$heading-6__size: map-deep-get($headlines, 'h6', 'sizes', 'xs');
$heading-6__line-height: map-deep-get($headlines, 'h6', 'line-height') !default;
$heading-6__margin-top: map-deep-get($headlines, 'h6', 'margin-top') !default;
$heading-6__margin-bottom: map-deep-get($headlines, 'h6', 'margin-bottom') !default;
$paragraph__link-color: $color-links !default;
$paragraph__link-decoration: underline !default;
$paragraph__link-decoration--hover: none !default;
$code__color: $color-highlight !default;
$pre__color-background: $color-gray !default;
$pre__color: #fff !default;
$selection-background: $color-brand;
$selection-color: $color-text--inverted;
$hr-border: $base-border;

// USED FROM _variables.scss

// --------------------------------------------------

// Headings 1–6.
#{headings()} {
    // [1]
    font-weight: $headings__font-weight;
    font-family: $headings__font-family;
}

// H1 — h6: Generate Element Styles from config
// [1] more space between last text-element and new headline
// [2] combined headlines - example: h1 + h2
// [3] correct margin-top for headlines, when in a new content element
// [4] $base-spacing-unit = default margin-bottom for most elements
// [5] ex: h1 + h2
// [6] new placeholder because scssphp in Contao fails with %#{$element}
// [7] base font size
// [8] loop through all viewports and add font-size for every viewport

@each $element, $options in $headlines {
    $count: list.index(map.keys($headlines), $element);

    %#{$element},
    %_#{$element} {
        $sizes: map.get($options, sizes);
        $line-height: map.get($options, line-height);
        $margin-top: map.get($options, margin-top);
        $margin-bottom: map.get($options, margin-bottom);
        margin-bottom: $margin-bottom;
        font-weight: $headings__font-weight;

        font-size: map.get($sizes, xs); // [7]
        font-family: $headings__font-family;
        line-height: $line-height;

        // [8]
        @for $i from 2 through list.length($sizes) {
            $current: list.nth($sizes, $i); // $i = 2 = sm, $i = 5 = xl
            $viewport: list.nth($current, 1);
            $font-size: list.nth($current, 2);

            @include media-query(screen-#{$viewport}) {
                font-size: $font-size;
            }
        }
    }

    #{$element} {
        @extend %_#{$element};

        $sizes: map.get($options, sizes);
        $line-height: map.get($options, line-height);
        $margin-top: map.get($options, margin-top);
        $margin-bottom: map.get($options, margin-bottom);

        p + &,  // [1]
        ul + &,
        ol + & {
            margin-top: $margin-top;
        }

        .content-text + .content-text & {
            &:first-child {
                margin-top: $margin-top - $base-spacing-unit; // [4]
            }
        }

        @if $count < 6 {
            & + h#{$count + 1} {
                // [5]
                margin-top: -1 * $margin-bottom;
            }
        }
    }
}

// Paragraph, Lists
p,
ul,
ol {
    margin-top: 0;

    a {
        color: $paragraph__link-color;
        text-decoration: $paragraph__link-decoration;

        &:hover {
            text-decoration: $paragraph__link-decoration--hover;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

p + h2,
p + h3 {
    // margin-top: 2rem;
}

// Code
code {
    color: $code__color;
}

pre {
    padding: 1rem;
    overflow-x: scroll;
    color: $pre__color;
    background: $pre__color-background;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: $hr-border;
}

// list-item with more than one line of text should have additional space to the following list-item
.li--block {
    margin-bottom: 1rem;
}

[class*='content-'] {
    overflow: hidden;
}

.content-text {
    margin-bottom: $base-spacing-unit;
}

::selection {
    color: $selection-color;
    background: $selection-background;
    opacity: 1;
}

// --------------------------------------------------

#{headings()} {
    color: $headings__color;
}

h1 {
    text-transform: uppercase;
}

h5 {
    color: $color-text;
    font-weight: bold;
}

%heading-serif,
.heading--serif {
    font-family: freight-text-pro, serif;
    font-style: italic;
}

.heading--intro {
    @extend %heading-serif;
    font-size: 1.1rem;
}

#{headings()} {
    &.heading--colored,
    .heading--colored & {
        color: $color-brand;
    }
}

#{headings()} {
    &.heading--left,
    .heading--left & {
        text-align: left;
    }
}

#{headings()} {
    &.heading--center,
    .heading--center & {
        text-align: center;
    }
}

#{headings()} {
    &.heading--right,
    .heading--right & {
        text-align: right;
    }
}

h1 {
    &.heading--gt,
    .heading--gt & {
        font-size: 3rem;
        @include media-query(screen-sm-max) {
            line-height: 1;
        }
    }
}

.text--left {
    text-align: left;
}

.text--center {
    text-align: center;
}

.text--right {
    text-align: right;
}

.text--italic {
    font-style: italic;
}

.text--sm {
    font-size: 0.75rem;
}

.text--gt {
    font-size: 1.15rem;
}

.text--info {
    color: $headings__color;
    line-height: 1.25;
}

.text--colored {
    color: $color-brand;
}

ul {
    list-style-image: url('../../images/dash.svg');
}

sup {
    top: -0.75em;
    font-size: 0.5em;
}
