// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// COMPONENTS/NAVS
// --------------------------------------------------

// VARIABLES
$nav--main__item-padding: $base-spacing-unit !default;
$nav-toggler-top-space: $base-spacing-unit--sm !default;
$nav-toggler-right-space: $base-spacing-unit--sm !default;
$nav-toggler-border: $base-border-width solid currentColor !default;

// USED FROM _responsive.scss
// $offcanvas-nav

// --------------------------------------------------

// general nav class, navigation-mixin resets margin, padding and list-stype-type
.nav {
    @include navigation;
}

.nav--horizontal {
    ul {
        @extend %clearfix;
    }

    li {
        @include media-query(screen-offcanvas) {
            float: left;
        }
    }

    a,
    span.active,
    span.trail,
    strong.active,
    strong.trail {
        display: block;
        padding: ($nav--main__item-padding / 2) $nav--main__item-padding;
    }
}

.nav--right {
    float: right;
}

.nav--vertical {
    a,
    span.active,
    span.trail,
    strong.active,
    strong.trail {
        display: block;
        padding: ($nav--main__item-padding / 2) $nav--main__item-padding;
    }
}

.nav--border {
    border: $base-border;

    a,
    span.active,
    span.trail,
    strong.active,
    strong.trail {
        padding: $nav--main__item-padding $nav--main__item-padding;
        border-bottom: 1px solid $base-border-color;

        &.last {
            border-bottom: 0;
        }
    }
}

.nav--breadcrumb {
    padding: $base-spacing-unit--sm $base-spacing-unit;
    border: $base-border;

    ul {
        @extend %clearfix;
    }

    li {
        @include media-query(screen-offcanvas) {
            float: left;
            padding-right: $base-spacing-unit;
        }
    }

    a {
        &::after {
            display: inline-block;
            padding-left: $base-spacing-unit;
            font-size: 0.75rem;
            vertical-align: middle;
            content: '►';
        }
    }
}

.nav--select {
    select {
        padding: $base-spacing-unit--xs $base-spacing-unit--sm;
        background: transparent;
        border: $base-border;
        appearance: none;
    }

    .submit {
        padding: $base-spacing-unit--xs $base-spacing-unit--sm;
        vertical-align: middle;
    }
}

.nav--pager {
    ul {
        text-align: center;
    }

    li {
        display: inline-block;
    }

    .empty {
        @include invisible;
    }

    a {
        padding: $base-spacing-unit--sm;
    }
}

// [1] clearing floated elements like news--list items
.pagination {
    @include navigation;
    @extend %clearfix;

    clear: both; // [1]

    margin-top: $base-spacing-unit;
    line-height: 1;
    text-align: center;

    p {
        margin: $base-spacing-unit--xs;
        margin-left: 0;
        padding: $base-spacing-unit--xs;
        padding-left: 0;
        border-top: $base-border-width solid transparent;
    }

    li {
        display: inline;
    }

    a,
    span,
    strong {
        display: inline-block;
        margin: $base-spacing-unit--xs;
        padding: $base-spacing-unit--xs;
    }

    span,
    strong {
        border: $base-border;
    }

    a.next {
        margin-right: 0;
        padding-right: 0;
    }

    @include media-query(screen-sm) {
        p {
            float: left;
        }

        p + ul {
            float: right;
        }
    }
}

// --------------------------------------------------

.nav--main {
    @include media-query(screen-sm-max) {
        display: none;
        padding-top: 1rem;
        padding-bottom: 1rem;

        body:target & {
            display: block;
        }
    }

    a,
    strong.active,
    strong.trail {
        display: block;
        padding: 0.5rem 1.5rem;
        color: #c5d5e7;
        font-weight: 400;
        text-transform: uppercase;
    }

    strong.active,
    a:hover,
    a.trail {
        color: #fff;
    }

    @include media-query(screen-md) {
        ul li ul {
            position: absolute;
            z-index: 999;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.5s ease;
        }

        ul li:hover > ul,
        ul li:focus-within > ul,
        ul li ul:hover {
            display: block;
            visibility: visible;
            opacity: 1;
        }

        ul li ul li {
            clear: both;
            width: 100%;
        }
    }

    ul.level_2 {
        a,
        strong.active,
        strong.trail {
            padding: 0.25rem 1.5rem 0.25rem 3rem;
            text-transform: initial;
        }

        @include media-query(screen-md) {
            min-width: 12rem;
            margin-left: 1.5rem;
            padding: 0;
            text-align: left;
            background: #fff;
            box-shadow: 0 2px 8px rgb(0 0 0 / 20%);

            li {
                display: block;

                &:first-child {
                    padding-top: 1rem;
                }

                &:last-child {
                    padding-bottom: 1rem;
                }
            }

            &:hover {
                > li {
                    a,
                    strong.trail {
                        color: #c5d5e7;
                    }
                }
            }

            > li:hover {
                a,
                strong.active,
                strong.trail {
                    color: $color-links;
                }
            }

            a,
            strong.active,
            strong.trail {
                padding: 0.25rem 1.5rem;
                color: $color-links;
                text-transform: initial;
            }
        }
    }
}

.nav--center {
    @include media-query(screen-offcanvas) {
        text-align: center;
    }

    li {
        @include media-query(screen-offcanvas) {
            display: inline-block;
        }
    }
}

.nav--changelanguage {
    position: absolute;
    top: 0;
    right: 2rem;
    @include media-query(screen-sm-max) {
        top: 1rem;
        right: 0.5rem;
        display: none;

        body:target & {
            display: block;
        }
    }

    ul {
        list-style: none;
    }

    li {
        padding: 0.8rem 0;

        @include media-query(screen-sm-max) {
            float: left;
        }
    }

    li + li {
        a,
        strong.active {
            border-left: 2px solid #c5d5e7;
        }
    }

    a,
    strong.active {
        padding: 0 0.5rem;
        color: #c5d5e7;
        line-height: 1;
    }

    strong.active {
        color: #fff;
    }
}

.nav--footer {
    @include media-query(screen-sm-max) {
        ul {
            text-align: center;
        }

        li {
            display: inline-block;
        }
    }

    a,
    strong.active,
    strong.trail,
    strong.forward {
        display: block;
        padding: 0.5rem 1rem;
        color: #fff;
        font-weight: 400;
        font-size: 0.9rem;
    }

    a:hover {
        text-decoration: underline;
    }
}

// We utilize the id of the body (body#top) to toggle the navigation via body:target
.nav-toggler {
    position: absolute;
    margin-top: -3rem;
    @include media-query(screen-md) {
        display: none;
    }

    &__close,
    &__open {
        display: block;
        width: 2rem;
        height: 2rem;
        text-indent: -99999px;
        background: url('../../images/menu-open.svg') no-repeat center;
        background-size: contain;
    }

    &__close {
        display: none;
        background-image: url('../../images/menu-close.svg');
    }

    body:target &__close {
        display: block;
    }

    body:target &__open {
        display: none;
    }
}
