// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// BASE/LAYOUT
// --------------------------------------------------

// VARIABLES
$header-color-background: $color-page-background !default;
$header-color-text: $color-text !default;

// USED FROM _variables.scss

// --------------------------------------------------

// #header
[id='header'] {
    color: $header-color-text;
    background: $header-color-background;

    .inside {
        @include wrapper-max;
        @include padding-default;
        @extend %clearfix;
    }
}

// #main
// [1] make sure object-fit-images always show up
.mod_article {
    @include wrapper-max;
    @include padding-default;

    position: relative; // [1]
    padding-top: $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--lg;
}

.article--full {
    @include wrapper-max(none);

    padding-top: 0;
    padding-bottom: 0;
}

// #footer
[id='footer'] {
    > .inside {
        @include wrapper-max;
        @include padding-default;
        @extend %clearfix;
    }
}

// --------------------------------------------------

[id='header'] {
    background: #fff;
    border-top: 0.25rem solid $color-brand;
}

[id='navbar'] {
    position: relative;
    background: $color-brand;
    box-shadow: 0 14px 25px -10px rgb(0 0 0 / 22%);

    .inside {
        @include wrapper-max;
        @include padding-default;
        @extend %clearfix;
    }
}

.mod_article:first-of-type {
    padding-top: 6rem;
}

.mod_article:last-of-type {
    padding-bottom: 6rem;
}

[id='footer'] {
    background: #111;

    .inside {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

// Sticky footer
html,
body,
[id='wrapper'] {
    height: 100%;
}

[id='wrapper'] {
    display: grid;
    grid-template-rows: auto auto 1fr auto;
}
