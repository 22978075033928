@use 'sass:math';

// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// COMPONENTS/MEDIA
// --------------------------------------------------

// VARIABLES
$gallery__gutter: $grid__gutter !default;
$gallery__gutter--half: ($grid__gutter / 2) !default;

// USED FROM _variables.scss

// --------------------------------------------------
.image--left img {
    margin-left: 0;
    text-align: left;
}

.image--right img {
    margin-right: 0;
    text-align: right;
}

.image--center img {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.content-gallery {
    ul {
        @extend %clearfix;
        margin-right: $gallery__gutter--half * -1;

        margin-left: $gallery__gutter--half * -1;
        padding: 0;
        list-style-type: none;
    }

    li {
        @include column-floating($gallery__gutter--half);

        margin-bottom: $gallery__gutter;
    }

    .cols_2 {
        li {
            width: 50%;
        }
    }

    .cols_3 {
        li {
            width: 50%;

            @include media-query(screen-md) {
                width: math.percentage(4 / 12);
            }
        }
    }

    .cols_4 {
        li {
            width: 50%;

            @include media-query(screen-md) {
                width: math.percentage(3 / 12);
            }
        }
    }

    .cols_5 {
        li {
            width: 50%;

            @include media-query(screen-md) {
                width: 20%;
            }
        }
    }

    .cols_6 {
        li {
            width: 50%;

            @include media-query(screen-md) {
                width: math.percentage(2 / 12);
            }
        }
    }

    img {
        display: block;
    }
}

.content-player,
.content-youtube {
    .inside,
    .mejs-video,
    .mejs__video {
        @include margin-auto;
    }

    .mejs-video,
    .mejs__video {
        width: 100% !important;
        height: auto !important;
        padding-top: 56.25%;
    }

    .mejs-audio,
    .mejs__audio {
        margin-top: 30px;

        audio {
            height: auto;
        }
    }

    .mejs-overlay,
    .mejs-poster,
    .mejs__overlay,
    .mejs__poster {
        width: 100% !important;
        height: 100% !important;
    }

    .mejs-video {
        .mejs-mediaelement video,
        .mejs-mediaelement .me-plugin,
        .mejs-mediaelement embed {
            position: absolute;
            inset: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    .mejs__video {
        .mejs__mediaelement video,
        .mejs__mediaelement .me__plugin,
        .mejs__mediaelement embed {
            position: absolute;
            inset: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.content-youtube {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        inset: 0;
        width: 100% !important;
        height: 100% !important;
        border: 0;
    }
}

// --------------------------------------------------

.social-icons {
    > *:first-of-type {
        padding-top: 0.75rem !important;
        border-top: 1px solid #ddd;
    }

    > *:last-of-type {
        padding-bottom: 0.75rem !important;
        border-bottom: 1px solid #ddd;
    }

    .content-hyperlink {
        @include media-query(screen-md) {
            padding: 0 0.75rem;
        }

        + .content-hyperlink {
            margin-top: 0.5rem;
        }
    }

    img {
        width: 100%;
    }
}

figcaption {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    color: $color-brand;
    font-size: 1.25rem;
    text-align: center;
}
