// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// BASE/SHARED
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

// set a default margin-bottom for some elements
// [1] call every headline element (h1-h6)
#{headings()} {
    // [1]
    margin-top: 0;
    margin-bottom: $base-spacing-unit--sm;
}

ul,
ol,
dl,
blockquote,
p,
address,
hr,
fieldset,
figure,
pre {
    margin-bottom: $base-spacing-unit;
}

// set a default margin-left for some elements
ul,
ol,
dd {
    margin-left: $base-spacing-unit;
}

// Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
.clearfix,
%clearfix {
    &::after {
        display: table;
        clear: both;
        content: '';
    }
}

// hyperlink
a {
    color: $color-links;
}

:not(p) a {
    text-decoration: none;
}

// images
figure {
    margin: 0;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}
