// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE

// COMPONENTS/TABLES
// --------------------------------------------------

// VARIABLES
$table-background-light: $color-gray-light;

// USED FROM _variables.scss

// --------------------------------------------------

table,
%table {
    width: 100%;
    margin-bottom: $base-spacing-unit;
    padding: 0;
    border-spacing: 0;

    th,
    td {
        padding-top: 1.25rem;
        padding-bottom: 0;
        line-height: 1.25;
        vertical-align: top;
        border-bottom: 1px solid $color-text;
    }

    th {
        padding-top: 0;
        white-space: nowrap;
        border-bottom: 2px solid $color-text;

        &:first-of-type {
            text-transform: uppercase;
        }
    }

    th:last-of-type,
    td:last-of-type {
        text-align: right;
        vertical-align: bottom;

        .table--col-last-top-aligned & {
            vertical-align: top;
        }
    }
}

thead,
th {
    text-align: left;
}

tfoot {
    font-style: italic;
}

.table--striped {
    .even {
        background: $table-background-light;
    }
}

.table--overflow {
    -ms-overflow-x: auto;
    overflow-x: scroll;
}
