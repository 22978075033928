// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// COMPONENTS/ACCORDION
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

.adpanel {
    margin-top: $base-spacing-unit--lg;

    + .adpanel {
        margin-top: 5rem;
    }

    &__img {
        img {
            width: 100%;
            @include media-query(screen-sm-max) {
                width: 66.67%;
                margin-bottom: 1rem;
            }

            @include media-query(screen-md) {
                // Do not use entire width in grid
                padding-right: $grid__gutter--half;
            }
        }

        &--90 {
            img {
                width: 90%;
            }
        }
    }
}

.adpanel-category {
    img {
        max-width: 75%;
    }

    @include media-query(screen-sm-max) {
        .col-xs-6 {
            margin-bottom: 2rem;
        }
    }
}

// [1] fix "line height" to position last line of headline to bottom line
.context-panel-meta {
    display: flex;
    align-items: flex-end;
    overflow: visible;

    figure {
        width: 5rem;
        margin-right: 0.5rem;
    }

    h3 {
        margin-bottom: -0.2rem; // [1]
        line-height: 1;
    }
}

.toggle-img {
    margin-bottom: 2rem;
    color: #7b7b7b;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    background: #ececec;
    border-radius: 0.125rem;
    box-shadow: 1px 1px 2px #ddd;
}

.col-md-8 + .col-md-3.offset-md-1 {
    @include media-query(screen-sm-max) {
        margin-top: 4rem;
    }
}
