// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE

// COMPONENTS/FORMS
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

.logo {
    a {
        display: block;
        width: 14rem;
        height: 5rem;
        margin: 0 auto;
        text-indent: -9999px;
        background: url('../../images/logo.svg') no-repeat center center;
        background-size: contain;

        @include media-query(screen-md) {
            width: 18.8rem;
            height: 6rem;
        }
    }
}
